import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@lmig/lmds-react/base.css';
import * as local from './config/env.local.json';
import * as qa from './config/env.qa.json';
import * as prod from './config/env.prod.json';
import { BrowserRouter } from 'react-router-dom';
declare global {
  interface Window { env?: any; }
}

const init = (env:any) => {
  window.env = env;
  ReactDOM.render(
    <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
  );
}

//only load the one environment config file we need
if (window.location.hostname === "localhost")
    init(local);
else if (window.location.hostname === "safesite.qa.safeco.com")
    init(qa);
else
    init(prod);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
